import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import SchoolAdminStoreType from "@/views/PlatformAdmin/SchoolAdmin/Store/indexType";

@Module({
    name:"SchoolAdmin",
    store,
    dynamic: true,
})
export default class SchoolAdmin extends VuexModule implements SchoolAdminStoreType{
    SchoolAdminSelect: any = {};
    get getSchoolAdminSelect(){
        return this.SchoolAdminSelect
    }
    @Mutation
    SetSchoolAdminSelect(data: any): void {
        this.SchoolAdminSelect = data
    }

}

export const SchoolAdminStore = getModule(SchoolAdmin )
