import Storage from "@/util/Storage";
import {delAreaList,getAreaList,} from "@/Api/ShopAdmin";
import {Toast} from "vant";

// 查询店铺 骑手 运行配送区域
export function getShopAreaList(body:any = {}) {
    const userId = Storage.GetData_!("userId")
    const token = Storage.GetData_!("token")
    return Promise.resolve(getAreaList({userId,token},body).then(res=>{
        return res.data
    }))
}

// 删除店铺 骑手 运行配送区域
export function delShopAreaList(id:string|number = "") {
    const userId = Storage.GetData_!("userId")
    const token = Storage.GetData_!("token")
    Toast.loading({ duration:0,message:"删除中~~~" })
    return Promise.resolve(delAreaList({ userId,token },{ id }).then(res=>{
        Toast.clear()
        return res.data
    }))
}
